import gsap, {Power2,Power3} from 'gsap'
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
gsap.registerPlugin(DrawSVGPlugin)

export default {

    data() {
        return {
            slider: null,
            tl: null,
        }
    },
    methods: {
        initSlider() {
            this.$slider = $("#slider");
            
            this.$slider.slider({
                range: false,
                min: 0,
                max: 100,
                step: 0.02,
                value: 0,
                slide: (event, ui) => {
                    this.tl.progress(ui.value / 100).pause();
                },
                stop: () => {
                    this.tl.play();
                }
            });
        },
        updateSlider() {
            this.$slider.slider("value", this.tl.progress() * 100);
        },
        initTimeline() {

            var vw = $(window).width();
            var vh = $(window).height();
            var speed = 0.85;

            this.tl = gsap.timeline({
                onUpdate: this.updateSlider,
                paused: true
            });
            this.tl.timeScale(2.7);

            // Timeline
            this.tl.fromTo($('.connect__top__left-image').find('.connectTop'), speed,
                { x: 0, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                0);

            this.tl.add(this.getConnectTopTl(), 0.2);

        },
        getConnectTopTl() {
            var ConnectTopTl = gsap.timeline({})
            let stagger = 0.3;

            ConnectTopTl.fromTo($('.top-arc'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            ConnectTopTl.fromTo($('.top-vertical'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);

            ConnectTopTl.fromTo($('.right-arc'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            ConnectTopTl.fromTo($('.right-horizontal'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1);

            ConnectTopTl.fromTo($('.middle-vertical'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            ConnectTopTl.fromTo($('.middle-horizontal'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);

            ConnectTopTl.fromTo($('.middle-arc'), 2, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 1.5);

            ConnectTopTl.fromTo($('.connectTopSvgLinkedImage'), 1, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=2");
            ConnectTopTl.fromTo($('.connectTopSvgFill'), 1, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=2");

            return ConnectTopTl;
        }
    }

}
